import request from '@/utils/request'

/**
 * 查询订单
 */
export const orderList = (data) => {
  return request({
    url: `/admin/order/list?pageIndex=${data.pageIndex}&pageSize=${data.pageSize}`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}
