<template>
  <div class="user-manage-container">
    <el-card class="header table-search" v-if="false">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="产品名称">
          <el-input
            v-model="formInline.user"
            placeholder="请输入产品名称"
            clearable
          />
        </el-form-item>
        <el-form-item label="更新时间">
          <el-date-picker
            v-model="formInline.date"
            type="date"
            placeholder="请选择更新时间"
            clearable
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :icon="Search" @click="onSubmit"
            >查询</el-button
          >
          <el-button :icon="Delete" @click="reset"> 重置 </el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card>
      <div class="filter-container" v-if="false">
        <el-button type="primary" :icon="Upload">
          导入产品
        </el-button>
        <el-button type="danger" plain disabled :icon="Delete">
          删除
        </el-button>
      </div>
      <el-table
        v-loading="loading"
        element-loading-text="正在加载中..."
        :data="tableData"
        border
        style="width: 100%"
        header-cell-class-name="table-header"
      >
        <el-table-column label="序号" type="index" align="center" width="70" />
        <el-table-column prop="productId" label="产品ID" align="center">
        </el-table-column>
        <el-table-column prop="ower" label="所属用户" align="center">
        </el-table-column>
        <el-table-column prop="price" label="订单价格" align="center">
        </el-table-column>
        <el-table-column prop="status" label="订单状态" align="center">
          <template #default="{ row }">
            <el-tag v-if="row.status === 3" type="primary">支付失败</el-tag>
            <el-tag v-if="row.status === 2" type="warning">支付成功</el-tag>
            <el-tag v-else type="danger">未支付</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="订单类型" align="center">
          <template #default="{ row }">
            <el-tag v-if="row.type === 1" type="primary">二维码订单</el-tag>
            <el-tag v-else type="warning">兑换码订单</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="redeemCode" label="兑换码" align="center">
        </el-table-column>
        <el-table-column label="创建时间" align="center">
          <template #default="{ row }">
            {{ $filters.dateFilter(row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column label="更新时间" align="center">
          <template #default="{ row }">
            {{ $filters.dateFilter(row.updateTime) }}
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        class="pagination"
        :background="true"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.pageIndex"
        :page-sizes="[2, 5, 10, 20]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script setup>
import { reactive, onActivated, onMounted, ref } from 'vue'
import { Delete, Search, Upload } from '@element-plus/icons-vue'
import { orderList } from '@/api/order'

const formInline = reactive({
  user: '',
  region: '',
  date: ''
})
// 数据相关
const tableData = ref([])
const loading = ref(false)
const page = reactive({
  pageIndex: 1,
  pageSize: 10,
  total: 0
})

const onSubmit = () => {
  console.log('submit!')
}

const reset = () => {
  console.log('reset!')
}

// 获取数据的方法
const getListData = async () => {
  loading.value = true
  const params = {
    pageIndex: page.pageIndex,
    pageSize: page.pageSize
  }
  const res = await orderList(params)
  tableData.value = res.datas
  page.total = res.records
  loading.value = false
}
onMounted(getListData)
// 处理导入用户后数据不重新加载的问题
onActivated(getListData)

// 分页相关
/**
 * size 改变触发
 */
const handleSizeChange = currentSize => {
  page.pageSize = currentSize
  getListData()
}
/**
 * 页码改变触发
 */
const handleCurrentChange = currentPage => {
  page.pageIndex = currentPage
  getListData()
}
</script>

<style lang="scss" scoped>
@import '~@/styles/mixin.scss';
.user-manage-container {
  .header {
    @include searchForm;
  }

  .filter-container {
    .el-button {
      margin-bottom: 15px;
    }
  }
  .table-edit-input {
    display: flex;
    align-items: center;
    justify-content: center;
    &:deep(.el-input) {
      width: 80px;
    }
    &:deep(.el-input--small .el-input__inner) {
      text-align: center;
    }
    .edit-icon {
      margin-left: 5px;
      cursor: pointer;
    }
  }

  :deep(.table-header) {
    @include tableThead;
  }
  .pagination {
    @include pagination;
  }
}
</style>
